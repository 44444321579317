import '../assets/css/Projects.css';
import projects from '../assets/json/projects.json';
import { Link } from 'react-router-dom';

let button = `<button class="closeModal">Close</button>`;

function loadInfo(e){
	let project = projects.find(project => project.key === e.target.id);
	let moreInfoModal = document.querySelector(`.moreInfoModal`);
	let sidebar = document.querySelector(`.sidebar`);
	let body = document.getElementsByTagName(`body`)[0];
	let mainContent = document.querySelector(`.mainContent`);

	moreInfoModal.innerHTML = `<div class='projectName'>${project.name}</div><div class='projectAbout'>${project.about}</div><div class='projectDetails'>${project.details}</div>${button}`;
	
	moreInfoModal.style.display = `flex`;
    body.style.overflowY = `hidden`;
    sidebar.style.pointerEvents = `none`;
    mainContent.style.pointerEvents = `none`;
}

function Projects(){
	return(
		<>
			<div className='projectList'>
				{projects.map((project, index) => (
					<div key={project.key} className={`project ${project.tags.join(' ')}`} tag={project.tag}>
						<div className={`locked fa-solid ${project.link ? `fa-unlock` : `fa-lock`}`}><span> {project.link ? `Public` : `Private`}</span></div>
						<div className='projectTitle'><span>{project.name}</span></div>
						<div className={`starred fa-solid ${project.icon} ${project.status.toLowerCase()}-project`}><span> {project.status}</span></div>

						<img src={require(`../assets/images/${project.image}`)} alt='' />
						<div className='projectLinks'>
							{project.link && (
								<Link className='fa-solid fa-link external' to={project.link} target='_blank' rel='noopener noreferrer'></Link>
							)}
							<div id={project.key} className='fa-solid fa-circle-info infoLink' onClick={loadInfo}></div>
						</div>
					</div>
				))}
			</div>
		</>
	);
}

export default Projects;