import Menu from '../components/Menu';
import '../assets/css/Resume.css';
import jobs from '../assets/json/jobs.json';
import fol1 from '../assets/images/nsls/fol1.png';
import fol2 from '../assets/images/nsls/fol2.png';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

function Resume(props){
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	})
	return(
		<div>
			<Menu page='resume' links={props.links} info={props.info} />
			<div className='mainContent page'>
				<div className='transition'>
					<div className='resume'>
						<div className='pageTitle'>Resume</div>
						<div className='pageInfo'>This resume highlights the developer aspects of the various employers</div>
						<div className='resumeDetails'>
							<div className='resumeLeft'>
								<div className='sectionLabel'>Summary</div>
								<div className='sectionDetails'>
									<div className='detailHeader'>{props.info.name}</div>
									<div className='detail'>Innovative and results-driven Node.JS developer with over {new Date().getFullYear() - 2019} years of experience designing and developing user-centered applications and websites.</div>
								</div>
								<div className='sectionLabel'>Education</div>
								<div className='sectionDetails'>
									<Link className='schoolName' to={props.links.capella} target='_blank'>{props.info.school}</Link>
									<div className='program'>{props.info.degree}</div>
									<div className='years'>{props.info.graduation}</div>
									<div className='gpa'>{props.info.gpa} GPA</div>
									<div className='honors'>{props.info.honors.join(`, `)}</div>
								</div>
								<div className='sectionLabel'>Awards</div>
								<div className='sectionDetails'>
									<div className='awards'>
										<Link className='badgeLink' to={props.links.fol1} title='Click to verify' target='_blank'>
											<img className='badge' alt='NSLS Certificate Badge FOL 101-1' src={fol1} />
										</Link>
										<Link className='badgeLink' to={props.links.fol2} title='Click to verify' target='_blank'>
											<img className='badge' alt='NSLS Certificate Badge FOL 101-1' src={fol2} />
										</Link>
									</div>
								</div>
							</div>
							<div className='resumeRight'>
								<div className='sectionLabel'>Experience</div>
								<div className='sectionDetails'>
									{jobs.map((job, index) => (
										<>
											<Link to={job.url} key={index} className='companyName' target='_blank'>{job.companyName}</Link>
											<div className='years'>{job.years}</div>
											<div className='work'>
												{job.work.map((line, lineIndex) => (
													<li key={lineIndex}>{line}</li>
												))}
											</div>
										</>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
  
export default Resume;