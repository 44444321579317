import avatarImage from '../assets/images/profile-img.png';
import { Link } from 'react-router-dom';
import '../assets/css/Menu.css';

function Menu(props){
	return(
		<div className='sidebar'>
			<div className='sidebarTop'>
				<div className='avatar'>
					<img src={avatarImage} alt={props.info.name} title={props.info.name} className='avatarImg' />
				</div>
				<div className='myNameMenu'>{props.info.name}</div>
			</div>
			<div className='socialLinks'>
				<div className='socialButtons'>
					<Link to={props.links.discord} target='_blank'>
						<i className='fa-brands fa-discord social discordLink'></i>
					</Link>
					<Link to={props.links.linkedin} target='_blank'>
						<i className='fa-brands fa-linkedin social linkedinLink'></i>
					</Link>
					<Link to={props.links.github} target='_blank'>
						<i className='fa-brands fa-github social githubLink'></i>
					</Link>
				</div>
			</div>
			<div className='sidebarMiddle'>
				<div className='menuLinks'>
					<div className='menuItem'>
						<Link className={`${props.page === 'home' ? 'menuLink activeMenu' : 'menuLink'}`} to='/'>
							<i className={`${props.page === 'home' ? 'fa-solid fa-house activeMenu' : 'fa-solid fa-house'}`}></i>
							Home
						</Link>
					</div>
					<div className='menuItem'>
						<Link className={`${props.page === 'about' ? 'menuLink activeMenu' : 'menuLink'}`} to='/about'>
							<i className={`${props.page === 'about' ? 'fa-solid fa-user activeMenu' : 'fa-solid fa-user'}`}></i>
							About
						</Link>
					</div>
					<div className='menuItem'>
						<Link className={`${props.page === 'resume' ? 'menuLink activeMenu' : 'menuLink'}`} to='/resume'>
							<i className={`${props.page === 'resume' ? 'fa-solid fa-file activeMenu' : 'fa-solid fa-file'}`}></i>
							Resume
						</Link>
					</div>
					<div className='menuItem'>
						<Link className={`${props.page === 'portfolio' ? 'menuLink activeMenu' : 'menuLink'}`} to='/portfolio'>
							<i className={`${props.page === 'portfolio' ? 'fa-solid fa-compass-drafting activeMenu' : 'fa-solid fa-compass-drafting'}`}></i>
							Portfolio
						</Link>
					</div>
					<div className='menuItem'>
						<Link className='menuLink' to={props.links.email}>
							<i className='fa-solid fa-envelope'></i>
							Contact
						</Link>
					</div>
				</div>
			</div>
			<div className='sidebarBottom'>
				<div className='discord'>
					<div className='winterclan'>
						<Link to={props.links.winterClanDiscord} target='_blank' title='Winter Clan Discord'>
							<img className='discordImg' src='https://discordapp.com/api/guilds/211141622694543360/widget.png?style=banner2' alt='Winter Clan' />
						</Link>
					</div>
				</div>
				<div className='siteInfo'>
					<div className='copyright'>
						<div className='current copyrightText'>© 2020 ~ {new Date().getFullYear()} DMS Services</div>
						<div className='copyrightText'>All Rights Reserved</div>
						<Link className='policies' to='/policies'>Policies</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
  
export default Menu;