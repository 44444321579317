import About from './pages/About';
import Home from './pages/Home';
import Policies from './pages/Policies';
import Portfolio from './pages/Portfolio';
import Resume from './pages/Resume';
import Tracker from './pages/Tracker';
import links from './assets/json/links.json';
import info from './assets/json/info.json';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';

info.years = new Date().getFullYear() - 2019;

function App(){
	useEffect(() => {
		let entry = 0;
    	let combo = 0;

		window.addEventListener(`keydown`, async function(e){
			combo += e.keyCode;
			entry++;
		
			if(entry === 11 && combo === 452){
				window.location.href = links.backOffice;
			}
		});
		document.addEventListener(`contextmenu`, async function(e){
			e.preventDefault();
		});
	  
		window.addEventListener('wheel', function(e){
			if(e.ctrlKey){
			e.preventDefault();
			}
		  }, { passive: false });
	  
		window.addEventListener('keydown', function(e){
			if((e.ctrlKey && (e.key === '+' || e.key === '-')) || (e.key === 'Meta' && (e.key === '+' || e.key === '-'))){
				e.preventDefault();
			}
		});
	  
		return() => {
			window.removeEventListener(`keydown`, function(e){
				combo += e.keyCode;
				entry++;
		  
				if(entry === 11 && combo === 452){
					window.location.href = links.backOffice;
				}
			});
			document.removeEventListener(`contextmenu`, function(e){
				e.preventDefault();
			});
			window.removeEventListener('wheel', function(e){
				if(e.ctrlKey){
					e.preventDefault();
				}
			}, { passive: false });
			window.removeEventListener('keydown', function(e){
				if((e.ctrlKey && (e.key === '+' || e.key === '-')) || (e.key === 'Meta' && (e.key === '+' || e.key === '-'))){
					e.preventDefault();
				}
			});
		}
	}, []);

	return(
		<>
			<BrowserRouter>
					<Routes>
						<Route path="/" element={<Home links={links} info={info}/>} />
						<Route path="/about" element={<About links={links} info={info}/>} />
						<Route path="/portfolio" element={<Portfolio links={links} info={info}/>} />
						<Route path="/resume" element={<Resume links={links} info={info}/>} />
						<Route path="/policies" element={<Policies links={links} info={info} />} />
						<Route path="/tracker" element={<Tracker links={links} info={info} />} />
					</Routes>
			</BrowserRouter>
		</>
	);
}
  
export default App;