import kasprIcon from '../assets/images/icon.png';
import '../assets/css/Matrix.css';
import Matrix from '../components/Matrix';
import Menu from '../components/Menu';
import React, { useEffect } from 'react';
import Typed from 'typed.js';

function Home(props){
	let el = React.useRef(null);
	let typed = React.useRef(null);

	useEffect(() => {
		let options = {
			strings: ['Developer: Node.JS', 'Developer: JavaScript', 'Developer: HTML/CSS', 'Developer: React.JS', 'Database Administration'],
			typeSpeed: 25,
			backSpeed: 50,
			loop: true,
			smartBackspace: true,
			backDelay: 1500,
		};
		
		typed.current = new Typed(el.current, options);

		return() => {
			typed.current.destroy();
		};
	}, []);

	return(
		<div>
			<Menu page='home' links={props.links} info={props.info} />
			<Matrix />
			<div className='introContent'>
				<div className='myName'>{props.info.name}</div>
				<div className='typed'>
					<div className='typedSkills'><span style={{ whiteSpace: 'pre' }} ref={el} /></div>
				</div>
			</div>
			<div className='iconContainer'>
				<img src={kasprIcon} alt='Avatar Icon' />
			</div>
		</div>
	);
}
  
export default Home;